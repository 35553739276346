import React from 'react';
import PropTypes from 'prop-types';

import DesktopMenu from './components/DesktopMenu';
import MobileMenu from './components/MobileMenu';

import Icon0Url from '../../../resources/icons/icon-00.svg';
import Icon1Url from '../../../resources/icons/icon-01.svg';
import Icon2Url from '../../../resources/icons/icon-02.svg';
import Icon3Url from '../../../resources/icons/icon-03.svg';
import Icon4Url from '../../../resources/icons/icon-04.svg';
import Icon5Url from '../../../resources/icons/icon-05.svg';
import Icon6Url from '../../../resources/icons/icon-06.svg';

export function getIcon(name) {
  switch(name) {
    default:
    case 'icon-00.svg':
      return Icon0Url;
    case 'icon-01.svg':
      return Icon1Url;
    case 'icon-02.svg':
      return Icon2Url;
    case 'icon-03.svg':
      return Icon3Url;
    case 'icon-04.svg':
      return Icon4Url;
    case 'icon-05.svg':
      return Icon5Url;
    case 'icon-06.svg':
      return Icon6Url;
  }
}

class AppMenuWrapper extends React.Component {
  render() {
    if (this.props.isMobile) {
      return <MobileMenu {...this.props} />;
    }
    return <DesktopMenu {...this.props} />;
  }
}

AppMenuWrapper.defaultProps = {
  page: 0,
};

AppMenuWrapper.propTypes = {
  // props
  isMobile: PropTypes.bool,
  fullscreen: PropTypes.bool,
  page: PropTypes.number,
  // funcs
  onNavigate: PropTypes.func.isRequired,
  onToggleFullscreen: PropTypes.func.isRequired,
  onZoom: PropTypes.func.isRequired,
};

export default AppMenuWrapper;
