export default function fetchProgress(url, onProgress = undefined) {
  return new Promise((resolve, reject) => {
    const xmlHTTP = new XMLHttpRequest();
    xmlHTTP.responseType = 'blob';

    xmlHTTP.open('GET', url, true);
    xmlHTTP.onerror = (err) => {
      reject(err);
    };
    xmlHTTP.onload = (res) => {
      if (xmlHTTP.status === 200) {
        resolve(xmlHTTP.response);
      } else {
        reject(res);
      }
    };
    if (onProgress) {
      xmlHTTP.onprogress = (res) => {
        onProgress(res.loaded / res.total, res.loaded, res.total, res);
      };
    }
    xmlHTTP.send();
  });
};