import React from 'react';
import PropTypes from 'prop-types';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import Box from '@mui/material/Box';

class DocumentWrapper extends React.PureComponent {
  /**
   * RENDER
   */
  render() {
    const { classes, width, height, page, fileURL, onLoadSuccess, onRenderSuccess } = this.props;
    const useWidth = width > height ? width : undefined;
    const userHeight = width > height ? undefined : height;
   
    return (
      <Box sx={{ width, height }} className={classes.documentWrapper}>
        <Document
          className={classes.fileDocument}
          file={fileURL}
          onLoadSuccess={onLoadSuccess}
        >
          <Page
            width={useWidth * 2 }
            height={userHeight * 2}
            pageNumber={page}
            onRenderSuccess={onRenderSuccess}
          />
        </Document>
      </Box>
    );
  }
}

DocumentWrapper.defaultProps = {
  onLoadSuccess: () => {},
};

DocumentWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  // props
  display: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  page: PropTypes.number,
  fileURL: PropTypes.string,
  isMobile: PropTypes.bool,
  // func
  onLoadSuccess: PropTypes.func,
  onRenderSuccess: PropTypes.func.isRequired,
}

export default DocumentWrapper;
