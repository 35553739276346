import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { TransformComponent } from 'react-zoom-pan-pinch';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';

import DocumentNavigation from './DocumentNavigation';
import DocumentWrapper from './DocumentWrapper';

import styles from './styles';

class Main extends React.Component {
  /**
   * RENDER
   */

  renderLoading(display) {
    const { classes } = this.props;
    return (
      <Stack className={`${classes.documentLoading}${display ? '' : ' hide'}`} alignItems="center" justifyContent="center">
        <CircularProgress color="secondary" size={69} thickness={5.7} />
      </Stack>
    );
  }
  
  render() {
    const { 
      classes, 
      fileURL, 
      page, 
      pageMax, 
      displayType, 
      fullscreen, 
      width, 
      height, 
      renderingPageLeft,
      renderingPageRight,
      onLoadSuccess,
      onZoom,
      onRenderSuccess,
      isMobile,
    } = this.props;

    if (!fileURL) {
      return null;
    }

    const displaySecondPage = displayType === 'double' && page + 1 <= pageMax;
    const renderLoading = (!displaySecondPage && renderingPageLeft) || 
                          ( displaySecondPage && (renderingPageLeft || renderingPageRight));

        
    
    return (
      <div className={classes.main}>
        <Box className={`${classes.transformWrapper} ${fullscreen ? 'fullscreen' : ''}`}>
          <TransformComponent>
            {this.renderLoading(renderLoading)}
            <Stack 
              className={classes.fileContainer} 
              onDoubleClick={onZoom}
              alignItems="center" 
              justifyContent="center" 
              direction="row"
            >
              <DocumentWrapper
                isMobile={isMobile}
                classes={classes}
                fileURL={fileURL}
                width={width}
                height={height}
                page={page}
                onLoadSuccess={onLoadSuccess}
                onRenderSuccess={() => onRenderSuccess('renderingPageLeft')}
              />
              {displaySecondPage  && (
                <DocumentWrapper
                  isMobile={isMobile}
                  classes={classes}
                  fileURL={fileURL}
                  width={width}
                  height={height}
                  page={page +1}
                  onRenderSuccess={() => onRenderSuccess('renderingPageRight')}
                />
              )}
            </Stack>
          </TransformComponent>
        </Box>
        <DocumentNavigation {...this.props} />
      </div>
    );
  }
}

Main.propTypes = {
  classes: PropTypes.object.isRequired,
  // props
  isMobile: PropTypes.bool,
  displayType: PropTypes.oneOf(['double', 'single']),
  fullscreen: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  page: PropTypes.number,
  pageMax: PropTypes.number,
  fileURL: PropTypes.string,
  renderingPageLeft: PropTypes.bool,
  renderingPageRight: PropTypes.bool,
  // funcs
  onLoadSuccess: PropTypes.func.isRequired,
  onZoom: PropTypes.func.isRequired,
  onRenderSuccess: PropTypes.func.isRequired,
}

export default withStyles(styles)(Main);
