import React from 'react';
import ReactDOM from 'react-dom/client';
import isNil from 'lodash/isNil';

import './theme/fonts.css'
import './theme/tarteaucitron.css'

import './utils/Tarteaucitron';
import Device from './utils/Device';

import App from './container/App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

if (!isNil(Device.mobile())) {
  document.body.classList.add('mobile');
}