export const PDF_NAME = 'Les-actes_cycle-2024_Palladio.pdf';
export const PDF_NAME_HR = 'Les-actes_cycle-2024_Palladio.pdf';

export const SKIP_ANIMATION_STORAGE_KEY = 'SKIP_ANIMATION';

export const MENU_HIERARCHY = [
  {
    label: 'Déjà paru (listes des éditions)',
    focusLabel: 'Déjà paru',
    icon: 'icon-00.svg',
    color: 'darkblue',
    pages: [1, 1],
    list: [
      { label: '<b>2012 /</b> La ville de demain pour quels territoires&nbsp;? <br> Parrain : Patrick Braouezec', pages: [], url: "https://www.fondationpalladio.fr/download/ACTES_2011-2012_INSTITUT_PALLADIO.pdf" },
      { label: '<b>2013 /</b> La ville de demain pour quels habitants&nbsp;? <br> Parrain : Gérard Collomb', pages: [], url: "https://www.fondationpalladio.fr/download/ACTES_2012-2013_INSTITUT_PALLADIO.pdf" },
      { label: '<b>2014 /</b> La ville de demain pour quels usages&nbsp;? <br> Parrain : Jean-Louis Borloo', pages: [], url: "https://www.fondationpalladio.fr/download/INSTITUT_PALLADIO_ACTES_CYCLE_2014.pdf" },
      { label: '<b>2015 /</b> La ville de demain pour quelles valeurs&nbsp;? <br> Parrain : Alain Juppé', pages: [], url: "https://www.fondationpalladio.fr/download/INSTITUT_PALLADIO_ACTES_CYCLE_2015.pdf" },
      { label: '<b>2016 /</b> La ville de demain à l’ère de la responsabilité sociétale <br> Marraine : Anne Hidalgo', pages: [], url: "https://www.fondationpalladio.fr/download/INSTITUT_PALLADIO_ACTES_CYCLE_2016.pdf" },
      { label: '<b>2017 /</b> La ville de demain : quelle place pour le travail&nbsp;? <br> Parrain : Xavier Bertrand', pages: [], url: "https://www.fondationpalladio.fr/download/INSTITUT_PALLADIO_ACTES_CYCLE_2017.pdf" },
      { label: '<b>2018 /</b> Habiter la ville de demain <br> Marraine : Johanna Rolland', pages: [], url: "https://www.fondationpalladio.fr/download/INSTITUT_PALLADIO_ACTES_CYCLE_2018.pdf" },
      { label: '<b>2019 /</b> Échanger biens et services innovants dans la ville de demain <br> Marraine : Valerie Pécresse', pages: [], url: "https://www.fondationpalladio.fr/download/INSTITUT_PALLADIO_ACTES_CYCLE_2019.pdf.pdf" },
      { label: '<b>2020 /</b> La ville de demain au service des savoirs <br> Parrain : Bernard Cazeneuve', pages: [], url: "https://www.fondationpalladio.fr/download/INSTITUT_PALLADIO_ACTES_CYCLE_2020_bd.pdf" },
      { label: '<b>2021 /</b> Bien-être et santé dans la ville de demain <br> Parrain : Edouard Philippe', pages: [], url: "https://www.fondationpalladio.fr/download/INSTITUT_PALLADIO_ACTES_CYCLE_2021..pdf" },
      { label: '<b>2022 /</b> Pour une civilisation urbaine : les défis de l’espace public <br> Marraine : Nathalie Appéré', pages: [], url: "https://www.fondationpalladio.fr/download/INSTITUT_PALLADIO_Actes_Cycle_2022" },
      { label: '<b>2023 /</b> (Ré)concilier la ville avec la nature <br> Parrain : Philippe Close', pages: [], url: "https://www.fondationpalladio.fr/download/INSTITUT_PALLADIO_Actes_Cycle_2023.pdf" },
      { label: '<b>2024 /</b> Prendre soin de la ville <br> Marraine : Catherine Vautrin', pages: [], url: "" },
    ],
  },
  {
    label: 'Préface',
    focusLabel: 'Préface',
    icon: 'icon-01.svg',
    color: 'darkblue',
    pages: [2, 3],
  },
  {
    label: 'Avant propos',
    focusLabel: 'Avant propos',
    icon: 'icon-02.svg',
    color: 'darkblue',
    pages: [3, 4],
  },
  {
    label: 'Ouverture de cycle',
    focusLabel: 'Ouverture de cycle',
    icon: 'icon-03.svg',
    color: 'darkblue',
    pages: [4, 5],
  },
  {
    label: 'Principaux enseignements du cycle',
    focusLabel: 'Principaux enseignements du cycle',
    icon: 'icon-04.svg',
    color: 'darkblue',
    pages: [6, 7],
  },
  {
    label: 'Contributions',
    focusLabel: 'Contributions',
    icon: 'icon-05.svg',
    color: 'darkblue',
    pages: [7, 8],
    list: [
      { label: 'Séminaire 1 : Grand Reims', pages: [8, 9] },
      { label: 'Séminaire 2 : L’approche globale de la ville', pages: [8, 9] },
      { label: 'Séminaire 3 : Les défis de l’urbanisation du monde et la convergence des crises&nbsp;:<br> Inégalités ; écologie urbaine et limites planétaires ; promesses et menaces de la ville digitale', pages: [8, 9] },
      { label: 'Séminaire 4 : Prendre soin de la ville&nbsp;: quels fondements philosophiques ; quel changement de modèle ?', pages: [8, 9] },
      { label: 'Séminaire 5 : Maintenir, entretenir, gérer&nbsp;:<br> qui s’occupe de quoi et comment ?', pages: [8, 9] },
      { label: 'Séminaire 6 : Le souci de la ville existante', pages: [8, 9] },
      { label: 'Séminaire 7 : Changement de modèle', pages: [8, 9] },
    ],
  },
  {
    label: 'Fondateurs et Mécènes',
    focusLabel: 'Fondateurs et Mécènes',
    icon: 'icon-06.svg',
    color: 'darkblue',
    pages: [9, 10],
  },
];

export const MENU_DESKTOP_WIDTH = 350;
export const MENU_MOBILE_HEADER_HEIGHT = 85;
export const MENU_MOBILE_FOOTER_HEIGHT = 75;
export const NAVIGATION_HEIGHT = 52;
export const MOBILE_NAVIGATION_HEIGHT = 40;
// export const PDF_PAGE_RATIO = 210 / 297;
export const PDF_PAGE_RATIO = 420 / 297;
export const MODE_SINGLE_PAGE = false;
export const TITLE_LOADER = 'LUCIOLE';
export const PRESENTATION_TEXT = "Chez Luciole, nous créons des expériences événementielles inoubliables en concevant des stands qui capturent l'âme de votre marque. Chaque détail est pensé pour engager, émerveiller et laisser une empreinte durable dans l’esprit de vos visiteurs.";
export const DOWNLOAD_TEXT = 'Télécharger ce pdf';
export const SHARE_TEXT = 'Partager ce pdf';
export const SHARE_TEXT_FACEBOOK = 'Partager sur Facebook';
export const SHARE_TEXT_X = 'Partager sur X';
export const SHARE_TEXT_LINKEDIN = 'Partager sur LinkedIn';
export const SHARE_TEXT_COPYPASTE = 'Copier le lien dans le presse-papier';
export const SHARE_TEXT_COPYPASTE_SUCCESS = 'Lien copié dans le presse papier';

export const FOLLOW_TEXT = 'NOUS SUIVRE';

const SHARE_URL = encodeURIComponent(window.location.href);
const SHARE_DESCRIPTION = encodeURIComponent('Luciole');
const SHARE_TITLE = encodeURI('Luciole');
export const WEBSITE_URL = 'https://luciole.com/';
export const FACEBOOK_SHARE_PARAMS = `http://www.facebook.com/share.php?u=${SHARE_URL}`;
export const X_SHARE_PARAMS = `http://x.com/intent/tweet?text=${SHARE_DESCRIPTION}&url=${SHARE_URL}`;
export const LINKEDIN_SHARE_PARAMS = ` https://www.linkedin.com/shareArticle?mini=true&url=${SHARE_URL}&title=${SHARE_TITLE}&summary=${SHARE_DESCRIPTION}`;
export const LINKEDIN_URL = 'https://www.linkedin.com/company/fondation-palladio/';
export const YOUTUBE_URL = 'https://www.youtube.com/channel/UCDiiQnloWEu4L5OAK6Q_l_w/featured';
export const X_URL = 'https://x.com/FPalladio';

const CONFIG = {
  PDF_NAME,
  SKIP_ANIMATION_STORAGE_KEY,
  MENU_DESKTOP_WIDTH,
  MENU_MOBILE_HEADER_HEIGHT,
  MENU_MOBILE_FOOTER_HEIGHT,
  PDF_PAGE_RATIO,
  NAVIGATION_HEIGHT,
  MENU_HIERARCHY,
  MOBILE_NAVIGATION_HEIGHT,
  FACEBOOK_SHARE_PARAMS,
  X_SHARE_PARAMS,
  WEBSITE_URL,
};

export default CONFIG;
